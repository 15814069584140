import Filter from "./Filter";

/**
 * uses constructor from Filter class to ensure similar properties are set and equals() function exists.
 * Does not add any eventHandlers.
 * Note: this class exists purely for performance reasons
 */
export default class FilterDummy extends Filter {
  constructor(element: HTMLElement, type: string, enabled: boolean = false) {
    super(element, type, enabled);
  }

  // eslint-disable-next-line  class-methods-use-this
  override setupEventHandlers() {
    return true;
  }
}
