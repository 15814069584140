import Observer from "../../utils/observer";

export type FileListFilterObserverPayload = {
  identifier: string;
  state: boolean;
};

/**
 * Filter class
 * used internally by FilterSet instances.
 */
export default class Filter {
  readonly container: HTMLElement;

  readonly type: string;

  readonly identifier: string;

  private active: boolean;

  private observers: Observer<FileListFilterObserverPayload>[];

  private default: boolean;

  private enabled: boolean | undefined;

  /**
   * checks whether an object is a (suitable) filter by asserting some required properties.
   * @param filter {any} obj to test
   */
  static isFilter(filter: Filter): boolean {
    return (
      filter.identifier !== undefined &&
      filter.type !== undefined &&
      filter.container !== undefined
    );
  }

  /**
   * upgrades a dummy to a full-functional filter
   * @param filterDummy {Filter}
   */
  static fromDummy(filterDummy: Filter) {
    return new Filter(
      filterDummy.container,
      filterDummy.type,
      filterDummy.enabled
    );
  }

  /**
   *
   * @param {HTMLElement} element - HTML element containing the filter ui element. Click-Listener will be registered on this element.
   * @param {string} type
   * @param {boolean} [enabled=true] set false to disable click listener
   */
  constructor(element: HTMLElement, type: string, enabled: boolean = true) {
    this.container = element;

    const identifier = this.container.dataset
      ? this.container.dataset["filter"]
      : undefined;
    if (identifier === undefined)
      throw new Error(
        "Failed to create Filter: Element has no filter identifier."
      );

    this.type = type;
    this.identifier = identifier;
    this.active = false;
    this.observers = [];
    this.default = false;
    this.enabled = undefined;
    if (enabled) this.setEnabled(true);
    if (this.container) {
      this.setupEventHandlers();
    }
  }

  equals(filterLikeObject: Filter) {
    return (
      this.identifier === filterLikeObject.identifier &&
      this.type === filterLikeObject.type
    );
  }

  setupEventHandlers() {
    this.container.addEventListener("click", () => {
      if (this.isEnabled()) {
        if (this.isDefault() && this.getActiveState()) {
          // default element cannot be deactivated by click.
        } else {
          this.toggle();
        }
      }
    });
  }

  activate(silent = false) {
    return this.setActiveState(true, silent);
  }

  deactivate(silent = false) {
    return this.setActiveState(false, silent);
  }

  toggle(silent = false) {
    return this.setActiveState(!this.active, silent);
  }

  getActiveState() {
    return this.active;
  }

  setActiveState(state: boolean, silent: boolean = false) {
    const oldState = this.active;
    this.active = state;
    if (state) {
      this.container.classList.add("active");
    } else this.container.classList.remove("active");
    if (state !== oldState && !silent) {
      this.onChange();
    }
    return this.active;
  }

  isEnabled() {
    return this.enabled;
  }

  setEnabled(enabled: boolean) {
    this.enabled = enabled;
  }

  isDefault(): boolean {
    return this.default;
  }

  setDefault(val = true) {
    this.default = val;
  }

  addObserver(filterObserver: Observer<FileListFilterObserverPayload>) {
    this.observers.push(filterObserver);
  }

  onChange() {
    this.notifyObservers();
  }

  notifyObservers() {
    this.observers.forEach((observer) => {
      observer.notify({ identifier: this.identifier, state: this.active });
    });
  }
}
