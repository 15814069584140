import { FilterSetOptions } from "./FilterableFileList-types";
import Observer from "../../utils/observer";
import FilterSet, { FileListFilterSetObserverPayload } from "./FilterSet";
import Filter from "./Filter";
import FilterDummy from "./FilterDummy";

export default class FilterSetFactory {
  /**
   * creates a new FilterSet from the given configuration.
   * @param container - filterSet container element. Must contain all filter elements.
   * @param type - unique identifier for the filterSet.
   * @param elements - query selector pointing to all filter elements. Default: ".filterableFileList-filter"
   * @param exclusive - false allows multiple filters in this set to be active (resolved as logical OR). Default: true
   * @param combinationMode - select mode to apply multiple active filters. "AND" requires all filters to match, "OR" requires only one. Default: "OR"
   * @param defaultElement - query selector pointing to the default filter element for the filterSet. Usually, this is a "show all" button. Default: ".filterableFileList-filter--default"
   * @param activeElements - query selector pointing to initially activated filter elements. Default: ".filterableFileList-filter--active".
   * @param observer - Observer object to attach to the filterSet.
   */
  static create(
    {
      container,
      type = "unset",
      elements = ".filterableFileList-filter",
      exclusive = true,
      combinationMode = "OR",
      defaultElement = ".filterableFileList-filter--default",
      activeElements = ".filterableFileList-filter--active",
    }: FilterSetOptions,
    observer: Observer<FileListFilterSetObserverPayload>
  ): FilterSet {
    const containerNode: HTMLElement | null =
      container === undefined ? null : document.querySelector(container);
    if (!containerNode) {
      throw new Error(
        "Failed to create FilterSet: No container element found."
      );
    }
    const elementsList = containerNode.querySelectorAll<HTMLElement>(elements);
    const defaultElementNode =
      containerNode.querySelector<HTMLElement>(defaultElement);
    const activeElementsList =
      containerNode.querySelectorAll<HTMLElement>(activeElements);

    if (defaultElementNode === null || elementsList.length === 0) {
      throw new Error(
        "Failed to create FilterSet: Failed to find required DOM elements."
      );
    }

    const filterSet = new FilterSet({
      container: containerNode,
      type,
      exclusive,
      combinationMode,
    });
    const filters = Array.from(elementsList, (e) => new Filter(e, type));
    filterSet.addFilters(filters);
    if (defaultElementNode) {
      const tmp = new FilterDummy(defaultElementNode, type);
      let defaultFilter = filters.find((filter) => filter.equals(tmp));
      if (!defaultFilter) {
        defaultFilter = Filter.fromDummy(tmp);
        filterSet.addFilter(defaultFilter);
      }
      filterSet.setDefaultFilter(defaultFilter);
      filterSet.onChange(defaultFilter);
    }
    if (activeElements && activeElementsList.length) {
      let iterator = Array.from(activeElementsList);
      if (exclusive) {
        iterator = iterator.slice(0, 1);
      }
      iterator.forEach((element) => {
        const tmp = new FilterDummy(element, type);
        const filter = filters.find((f) => f.equals(tmp));
        if (filter) {
          filter.activate();
        }
      });
    }
    if (observer) {
      filterSet.addObserver(observer);
    }
    return filterSet;
  }
}
