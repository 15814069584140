import Observer from "../../utils/observer";
import HashArgumentHelper from "./HashArgumentHelper";
import { FileListObserverPayload } from "./FilterableFileList";

export default class HashArgumentObserver extends Observer<FileListObserverPayload> {
  constructor(hashArgumentHelper: HashArgumentHelper) {
    super((payload: FileListObserverPayload) => {
      const activeFiltersByType = new Map<string, string[]>();
      payload.activeFilters.forEach((filter) => {
        if (!filter.isDefault()) {
          const values = activeFiltersByType.get(filter.type);
          if (values !== undefined) {
            values.push(filter.identifier);
          } else activeFiltersByType.set(filter.type, [filter.identifier]);
        }
      });
      payload.filterSets.forEach((filterSet) => {
        if (!filterSet.isLocked())
          hashArgumentHelper.resetFilter(filterSet.getType());
      });
      activeFiltersByType.forEach((values, type) => {
        hashArgumentHelper.setFilter(type, values);
      });
    });
  }
}
