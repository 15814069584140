import Observer from "../../utils/observer";
import Filter, { FileListFilterObserverPayload } from "./Filter";

export type FileListFilterSetObserverPayload = {
  type: string;
  activeFilters: Filter[];
};

/**
 * FilterSet class.
 * Used internally by FileList
 */
export default class FilterSet {
  private container: HTMLElement;

  private type: string;

  private exclusive: boolean;

  private combinationMode: string;

  private filters: Filter[];

  private observers: Observer<FileListFilterSetObserverPayload>[];

  private default: Filter | undefined;

  private locked: boolean;

  /**
   *
   * @param {HTMLElement} container
   * @param {string} type
   * @param {boolean} [exclusive=true]
   * @param {string} [combinationMode="OR"]
   */
  constructor({
    container,
    type,
    exclusive = true,
    combinationMode = "OR",
  }: {
    container: HTMLElement;
    type: string;
    exclusive: boolean;
    combinationMode: string;
  }) {
    this.container = container;
    this.type = type;
    this.exclusive = exclusive;
    this.combinationMode = combinationMode;
    this.filters = [];
    this.observers = [];
    this.locked = false;

    /**
     * @type {Filter|undefined}
     */
    this.default = undefined;
  }

  getContainer() {
    return this.container;
  }

  getType(): string {
    return this.type;
  }

  getFilter(identifier: string): Filter | undefined {
    return this.filters.find((filter) => filter.identifier === identifier);
  }

  addFilter(filter: Filter) {
    if (!Filter.isFilter(filter)) {
      return false;
    }

    // identifier must be unique within filterSet
    if (this.getFilter(filter.identifier) !== undefined) {
      return false;
    }

    // type must match
    if (filter.type !== this.type) {
      return false;
    }

    const filterObserver = new Observer<FileListFilterObserverPayload>(() => {
      this.onChange(filter);
    });
    filter.addObserver(filterObserver);
    this.filters.push(filter);
    return true;
  }

  addFilters(filterArray: Filter[]) {
    if (!Array.isArray(filterArray)) {
      return false;
    }
    filterArray.forEach((filter) => {
      this.addFilter(filter);
    });
    return true;
  }

  /**
   *
   * @param {HTMLElement} element - the HTML element for which to decide whether to show or not with current filter config
   * @param {Function} compareFunc - a custom compare function. Receives two arguments: (element, filter) -> element is the current element, filter is the filter to check.
   * @returns {boolean} true if the element passed the test, i.e. should be shown
   */
  checkElement(
    element: HTMLElement,
    compareFunc: (elem: HTMLElement, filter: Filter) => boolean = function () {
      return false;
    }
  ): boolean {
    let result = false;
    switch (this.combinationMode) {
      case "AND":
        result = true;
        this.getActiveFilters().forEach((filter) => {
          result =
            result &&
            (filter.identifier === "*" || compareFunc(element, filter));
        });
        break;
      default:
        this.getActiveFilters().forEach((filter) => {
          result =
            result || filter.identifier === "*" || compareFunc(element, filter);
        });
        break;
    }
    return result;
  }

  /**
   *
   * @param {string} identifier
   * @returns the filter state, or undefined if no filter with the given identifier was found
   */
  getFilterState(identifier: string): boolean | undefined {
    const filter = this.getFilter(identifier);
    if (filter) return filter.getActiveState();
    return undefined;
  }

  /**
   * activates or deactivates filters by their unique identifier
   * @param identifier
   * @param state
   *
   * @return the new filter state, or undefined if no filter with the given identifier was found
   */
  setFilterState(identifier: string, state: boolean): boolean | undefined {
    const filter = this.getFilter(identifier);
    if (filter) return filter.setActiveState(state);
    return undefined;
  }

  setDefaultFilter(filter: Filter) {
    this.default = filter;
    filter.setDefault();
  }

  getActiveFilters(): Filter[] {
    return this.filters.filter((filter) => filter.getActiveState());
  }

  addObserver(filterObserver: Observer<FileListFilterSetObserverPayload>) {
    this.observers.push(filterObserver);
  }

  deactivateAll(silent: boolean = true) {
    for (const filter of this.filters) {
      if (filter.getActiveState()) {
        filter.deactivate(silent);
      }
    }
  }

  deactivateOthers(filterLikeObject: Filter, silent = true) {
    for (const filter of this.filters) {
      if (!filter.equals(filterLikeObject) && filter.getActiveState()) {
        filter.deactivate(silent);
      }
    }
  }

  reset() {
    this.deactivateAll();
    if (this.default) {
      this.default.activate();
    }
  }

  onChange(filter: Filter) {
    if (this.exclusive) {
      if (filter.getActiveState()) {
        this.deactivateOthers(filter);
      }
    } else if (this.default !== undefined) {
      if (this.default.equals(filter) && filter.getActiveState()) {
        this.deactivateOthers(filter);
      } else {
        this.default.deactivate(true);
      }
    }
    if (!this.getActiveFilters().length && this.default !== undefined) {
      this.default.activate(true);
    }
    this.notifyObservers();
  }

  lock() {
    this.locked = true;
  }

  unlock() {
    this.locked = false;
  }

  isLocked() {
    return this.locked;
  }

  notifyObservers() {
    this.observers.forEach(
      (observer: Observer<FileListFilterSetObserverPayload>) => {
        observer.notify({
          type: this.type,
          activeFilters: this.getActiveFilters(),
        });
      }
    );
  }
}
