// @ts-expect-error isotope.js does not provide type definitions
import Isotope from "isotope-layout";
import Observer from "../../utils/observer";
import {
  FilterSetOptions,
  IsotopeConfiguration,
  IsotopeObject,
} from "./FilterableFileList-types";
import Filter from "./Filter";
import FilterSet, { FileListFilterSetObserverPayload } from "./FilterSet";
import FilterSetFactory from "./FilterSetFactory";

export type FileListObserverPayload = {
  activeFilters: Filter[];
  filterSets: FilterSet[];
};

export default class FilterableFileList {
  container: HTMLElement;

  fileList: HTMLElement | null;

  identifier: string;

  noResults: HTMLElement | null;

  resetButtons: NodeListOf<HTMLElement> | null;

  sortButtons: NodeListOf<HTMLElement> | null;

  filterSets: FilterSet[];

  private isotopeConfig: IsotopeConfiguration;

  private filterSetConfigurations: FilterSetOptions[];

  private isotope: IsotopeObject | null;

  private fileListSelector: string;

  private itemsSelector: string;

  private noResultsSelector: string;

  private resetSelector: string;

  private sortButtonsSelector: string | null;

  private observers: Observer<FileListObserverPayload>[];

  private isInitialized: boolean = false;

  private isotopeDefaultConf = {
    layoutMode: "vertical",
    transitionDuration: "0.4s",
    getSortData: {},
    sortBy: "original-order",
  };

  /**
   *
   * creates a new FilterableFileList. Requires to be initialized by calling the init() function.
   * Observers can be attached to further handle filter changes / user interactions.
   *
   * Example usage: see js/news/mediathek.js
   *
   * A FilterSet configuration is required for each FilterSet you want to add. These look as follows:
   *  container - filterSet container element. Must contain all DOM Elements acting as clickable Filters for this set.
   *  type - unique identifier for the filterSet.
   *  elements - query selector pointing to all filter elements. Default: ".filterableFileList-filter"
   *  exclusive - false allows multiple filters in this set to be active (resolved as logical OR). Default: true
   *  combinationMode - Select mode to apply multiple active filters. "AND" requires all filters to match, "OR" requires only one. Default: "OR"
   *  defaultElement - query selector pointing to the default filter element for the filterSet. Usually, this is a "show all" button. Default: ".filterableFileList-filter--default"
   *  activeElements - query selector pointing to initially activated filter elements. Default: ".filterableFileList-filter--active".
   *
   *
   *
   *
   * @param {HTMLElement} htmlContainer - HTML Element containing the FileList Dom tree.
   * @param {Object} FileListConfig - FileList configuration
   * @param {string} FileListConfig.fileListSelector - query selector pointing to the fileList i.e. the element containing the actual items to be filtered.
   * @param {FilterSetOptions[]} FileListConfig.filterSets - FilterSets to initialize
   * @param {string} FileListConfig.itemsSelector - query selector pointing to ALL items that should be filtered. Such elements are usually contained in the fileList Dom Element, see fileListSelector.
   * @param {IsotopeConfiguration} [FileListConfig.isotope] - isotope.js configuration.
   * @param {string} FileListConfig.noResultsSelector - query selector pointing to a html element to be displayed if the filtering returned no items. Default: "filterableFileList--noResults"
   * @param {string} FileListConfig.resetSelector - query selector pointing to element(s) that should act as reset button. Default: ".filterableFileList--resetFilters"
   */
  constructor(
    htmlContainer: HTMLElement,
    {
      fileListSelector,
      filterSets,
      itemsSelector,
      isotope = {},
      noResultsSelector = ".filterableFileList--noResults",
      resetSelector = ".filterableFileList--resetFilters",
      sortButtonsSelector,
    }: {
      fileListSelector: string;
      filterSets: FilterSetOptions[];
      itemsSelector: string;
      isotope?: IsotopeConfiguration;
      noResultsSelector?: string;
      resetSelector?: string;
      sortButtonsSelector?: string;
    }
  ) {
    this.container = htmlContainer;
    this.filterSets = [];
    this.filterSetConfigurations = filterSets;
    this.isotopeConfig = Object.assign(this.isotopeDefaultConf, isotope);
    this.fileListSelector = fileListSelector;
    this.itemsSelector = itemsSelector;
    this.noResultsSelector = noResultsSelector;
    this.resetSelector = resetSelector;
    this.sortButtonsSelector = sortButtonsSelector ?? null;
    this.isotope = null;
    this.noResults = null;
    this.resetButtons = null;
    this.sortButtons = null;
    this.observers = [];
    this.fileList = null;
    this.identifier = "unset";
  }

  /**
   * Initializes a pre-configured object by connecting the dom tree.
   * Run this function once after the dom tree is ready.
   * @param {Observer[]|Observer} observers an Observer or array of observers to attach.
   */
  async init(
    observers:
      | Observer<FileListObserverPayload>[]
      | Observer<FileListObserverPayload> = []
  ): Promise<void> {
    const observerArray: Observer<FileListObserverPayload>[] = Array.isArray(
      observers
    )
      ? observers
      : [observers];

    if (this.container.dataset["filelistinitialized"] === "true") {
      const msg: string =
        "FileList: A FileList has already been initialized on this element. Aborting.";
      throw new Error(msg);
    }

    this.container.dataset["filelistinitialized"] = "true";
    this.fileList = this.container.querySelector(this.fileListSelector);
    if (this.fileList === null) {
      const msg: string =
        "FileList: Failed to find fileList elements container.";
      throw new Error(msg);
    }

    this.identifier =
      this.fileList.dataset["id"] ??
      ((): string => {
        const generatedId: string = `tx_sitepackage-fileList--${Date.now()}`;
        this.fileList.dataset["id"] = generatedId;
        return generatedId;
      })();

    this.noResults = this.container.querySelector<HTMLElement>(
      this.noResultsSelector
    );
    this.resetButtons = this.container.querySelectorAll<HTMLElement>(
      this.resetSelector
    );
    this.sortButtons = this.sortButtonsSelector
      ? this.container.querySelectorAll<HTMLElement>(this.sortButtonsSelector)
      : null;

    this.resetButtons.forEach((resetElem) => {
      resetElem.addEventListener("click", () => {
        this.reset();
      });
    });

    if (this.sortButtons !== null) {
      this.sortButtons.forEach((button) => {
        button.addEventListener("click", () => {
          const sortKey = button.dataset["sorting"];
          if (sortKey) {
            this.sort(sortKey);
            if (this.sortButtons)
              this.sortButtons.forEach((inactiveButton) => {
                inactiveButton.classList.remove(
                  "filterableFileList-sorting--active"
                );
              });
          }
          button.classList.add("filterableFileList-sorting--active");
        });
      });
    }

    this.isotope = new Isotope(this.fileList, {
      itemSelector: this.itemsSelector,
      layoutMode: this.isotopeConfig.layoutMode,
      filter: this.createFilterFunc(),
      transitionDuration: this.isotopeConfig.transitionDuration,
      getSortData: this.isotopeConfig.getSortData,
      sortBy: this.isotopeConfig.sortBy,
      initLayout: false,
    });

    this.filterSetConfigurations.forEach((filterSetOptions) => {
      try {
        const filterSet: FilterSet = FilterSetFactory.create(
          filterSetOptions,
          new Observer<FileListFilterSetObserverPayload>(() => {
            this.notifyObservers();
            this.renderElements();
          })
        );
        this.addFilterSet(filterSet);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: Error | any) {
        const msg = "Failed to create FilterSet";
        if (!(e instanceof Error)) {
          const err = new Error(msg);
          // eslint-disable-next-line no-console
          console.warn(err.message);
        }
        // eslint-disable-next-line no-console
        console.warn(e.message ?? msg);
      }
    });
    observerArray.forEach((observer: Observer<FileListObserverPayload>) => {
      this.addObserver(observer);
    });
    this.isInitialized = true;
    this.renderElements();
  }

  /**
   * adds a filterSet. Ensures unique type identifier.
   * @param filterSet
   * @throws Error
   */
  addFilterSet(filterSet: FilterSet): FilterSet {
    if (this.getFilterSetByType(filterSet.getType(), false) !== undefined) {
      throw new Error("Failed to add FilterSet: duplicated type identifier.");
    } else {
      this.filterSets.push(filterSet);
      return filterSet;
    }
  }

  renderElements(options = {}) {
    if (this.isotope === null || !this.isInitialized) {
      throw new Error("FileList was not initialized correctly.");
    }
    this.isotope.arrange(options);
    if (this.isotope.getFilteredItemElements().length > 0) {
      this.hideNoResults();
    } else {
      this.showNoResults();
    }
  }

  showNoResults() {
    if (this.noResults) this.noResults.style.display = "";
  }

  hideNoResults() {
    if (this.noResults) this.noResults.style.display = "none";
  }

  sort(sortKey: string) {
    this.isotopeConfig.sortBy = sortKey;
    this.renderElements({ sortBy: sortKey });
  }

  reset() {
    this.filterSets.forEach((filterSet) => {
      filterSet.reset();
    });
  }

  /**
   * Returns a function which is then used by isotope.js to decide whether an element matches the current filter. Set carefully.
   * The function receives one argument, which is the element to be checked.
   * @returns Function
   */
  createFilterFunc() {
    return (element: HTMLElement) => {
      let filterSetChecks = true;

      this.filterSets.forEach((filterSet) => {
        filterSetChecks =
          filterSetChecks &&
          filterSet.checkElement(element, (elem: HTMLElement, filter: Filter) =>
            // this function is executed to check a single list element whether to be displayed or not. Set this carefully.
            elem.classList.contains(
              `filter-${filterSet.getType()}--${filter.identifier}`
            )
          );
      });
      return filterSetChecks;
    };
  }

  /**
   *
   * @param {string} type filterSet type identifier. This is a string set via initial configuration.
   * @param {boolean} requireInit Saveguard to check wheter fileList was initialised successfully. Can be skipped.
   * @returns {FilterSet|undefined}
   */
  getFilterSetByType(
    type: string,
    requireInit: boolean = true
  ): FilterSet | undefined {
    if (requireInit && !this.isInitialized) {
      throw new Error("FileList was not initialized correctly.");
    }
    return this.filterSets.find((filterSet) => filterSet.getType() === type);
  }

  /**
   * attaches an observer. This observer is called when the filtering or sorting changes.
   * Calls Observer.notify() with the following payload:
   * { activeFilters: {Array<Filter>} the currently active filters
   *   filterSets: {Array<FilterSet>} an array containing all registered FilterSets as fully-qualified object
   *   (i.e. you can use their properties and methods.)
   * }
   *
   * @param {Observer} observer Observer to be added
   */
  addObserver(observer: Observer<FileListObserverPayload>) {
    this.observers.push(observer);
  }

  notifyObservers() {
    let activeFilters: Filter[] = [];
    this.filterSets.forEach((filterSet) => {
      activeFilters = activeFilters.concat(filterSet.getActiveFilters());
    });
    this.observers.forEach((observer) => {
      observer.notify({ activeFilters, filterSets: this.filterSets });
    });
  }

  /**
   * Returns the state of a specific filter withing a filterSet.
   * @param {string} filterSetType filterSet unique type identifier
   * @param {string} filterIdentifier filter identifier, must be unique within the filterSet
   * @returns {boolean|undefined}
   * @throws Error
   */
  getFilterState(
    filterSetType: string,
    filterIdentifier: string
  ): boolean | undefined {
    if (!this.isInitialized) {
      throw new Error("FileList was not initialized correctly.");
    }
    const filterSet = this.getFilterSetByType(filterSetType);
    return filterSet ? filterSet.getFilterState(filterIdentifier) : undefined;
  }

  /**
   *
   * Manually sets a filter state.
   *
   * @param {string} filterSetType - filterSet unique identifier.
   * @param {string} filterIdentifier - filter identifier, must be unique within the filterSet.
   * @param {boolean} state - the new state of the filter. True enables, false disables.
   * @param {boolean} isolate - if true, flags all other filterSets as locked until all observers have finished. Observers are meant to respect that and not modify anything related to the filterSet - e.g. URL Parameters.
   * @returns {boolean} true if the operation was successful, false otherwise.
   * @throws Error
   */
  setFilterState(
    filterSetType: string,
    filterIdentifier: string,
    state: boolean,
    isolate: boolean = false
  ): boolean {
    if (!this.isInitialized) {
      throw new Error("FileList was not initialized correctly.");
    }
    const filterSet = this.getFilterSetByType(filterSetType);
    if (filterSet !== undefined) {
      // locked state means that a filterSet has not been affected by the currently ongoing event.
      if (isolate) {
        // if set, flag all other filterSets as locked until observers have been notified.
        this.lockFilterSets();
        filterSet.unlock(); // unlock only affected FilterSet
      }
      filterSet.setFilterState(filterIdentifier, state);
      if (isolate) this.unlockFilterSets();
      return true;
    }
    return false;
  }

  private lockFilterSets() {
    this.filterSets.forEach((filterSet) => {
      filterSet.lock();
    });
  }

  private unlockFilterSets() {
    this.filterSets.forEach((filterSet) => {
      filterSet.unlock();
    });
  }
}
