export default class Observer<PayloadType> {
  handler: (payload: PayloadType) => unknown;

  /**
   * Creates a new Observer
   * @param {Function} handler - handler function. Receives payload as single argument.
   */
  constructor(handler: (payload: PayloadType) => unknown) {
    this.handler = handler;
  }

  notify(payload: PayloadType) {
    return this.handler(payload);
  }
}
