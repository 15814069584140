const legacyTagKey: string = "filterTag";
const legacyCategoryKey: string = "filterCat";
const valueDelimiter: string = "-";

export default class HashArgumentHelper {
  private url: URL;

  private convertedCategoryKey: string;

  private convertedTagKey: string;

  private validKeys: string[];

  static getHashFilter(key: string) {
    const matches = window.location.hash.match(
      new RegExp(`${key}=([^&]+)`, "i")
    );
    return matches && matches.length >= 2 ? decodeURIComponent(matches[1]) : "";
  }

  static transformLegacyIdentifier(legacyIdentifier: string) {
    // eslint-disable-next-line prefer-regex-literals
    const reg = new RegExp(
      ".(?:filter-cat-|filter-tag-)(?<filterId>\\d+)",
      "g"
    );
    const matches = legacyIdentifier.matchAll(reg);
    const ids = [];
    for (const match of matches) {
      if (match.groups && match.groups["filterId"])
        ids.push(match.groups["filterId"]);
    }
    return ids;
  }

  static encodeValues(values: string[]) {
    return values.join(valueDelimiter);
  }

  static decodeValues(valueString: string): string[] {
    return valueString.split(valueDelimiter);
  }

  constructor(
    location: Location,
    categoryIdentifier: string = "category",
    tagIdentifier: string = "tag"
  ) {
    this.url = new URL(location.href);
    this.convertedCategoryKey = categoryIdentifier;
    this.convertedTagKey = tagIdentifier;
    this.validKeys = [categoryIdentifier, tagIdentifier];
  }

  convertLegacyUrl() {
    if (this.url.hash.length === 0) return;
    const hashFilterCat = HashArgumentHelper.getHashFilter(legacyCategoryKey);
    const hashFilterTag = HashArgumentHelper.getHashFilter(legacyTagKey);

    this.url.hash = "";
    const reason = "legacyRedirect";
    if (hashFilterCat.length > 0)
      this.setFilterInternal(
        this.convertedCategoryKey,
        HashArgumentHelper.transformLegacyIdentifier(hashFilterCat),
        reason
      );
    if (hashFilterTag.length > 0)
      this.setFilterInternal(
        this.convertedTagKey,
        HashArgumentHelper.transformLegacyIdentifier(hashFilterTag),
        reason
      );
  }

  getFilter(key: string): string[] {
    const valueString = this.url.searchParams.get(key);
    if (valueString === null) return [];
    return HashArgumentHelper.decodeValues(valueString);
  }

  setFilter(key: string, values: string[]) {
    if (values.length > 0 && this.validKeys.includes(key)) {
      this.setFilterInternal(key, values, "filterSelect");
    }
  }

  resetFilter(key: string) {
    this.url.searchParams.delete(key);
    window.history.replaceState(
      { reason: "filterReset", undoable: false },
      "",
      this.url.href
    );
  }

  private setFilterInternal(key: string, values: string[], reason: string) {
    this.url.searchParams.set(key, HashArgumentHelper.encodeValues(values));
    window.history.replaceState({ reason, undoable: false }, "", this.url.href);
  }

  resetFilters() {
    this.validKeys.forEach((key) => {
      this.resetFilter(key);
    });
  }
}
